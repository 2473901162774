const initalstate = {
  rates: {},
};

export default function reducer(state = initalstate, action) {
  switch (action.type) {
    case 'GET_BID_RATES':
      return {
        ...state,
        rates: action.payload,
      };

    default:
      return state;
  }
}
