import React from 'react';
import { Rnd } from 'react-rnd';

function Draggable(props) {
  return (
    <div>
      <Rnd
        style={{
          zIndex: 1500,
        }}
        default={{
          x: 300,
          y: -550,
          // width: 500,
          // height: 290,
          width: 'auto',
          height: 'auto',
        }}
        minWidth={600}
        minHeight={300}
        resizeGrid={[0, 0]}
        dragGrid={[5, 5]}
        lockAspectRatio={16 / 3 || 1.33 / 1 || 1.78 / 1 || 4 / 3}
        scale={1}
        lockAspectRatioExtraHeight={0}
        lockAspectRatioExtraWidth={0}
        enableResizing={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        bounds='window'
        cancel='.disable-drag'
      >
        {props.children}
      </Rnd>
    </div>
  );
}

export default Draggable;
