import React from 'react';
import { Button, Stack, InputAdornment, Input, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';

function StakingWalletFileUpload({
  setSelectedFile,
  setInvalidFormat,
  isUploading,
  invalidFormat,
  selectedFile,
  callUploadFile,
  fullScreen,
}) {
  return (
    <Stack direction='row' alignItems='center' spacing={2} mb={2} mt={2}>
      <TextField
        disabled
        id='input-with-icon-textfield'
        sx={{
          width: '88%',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <label htmlFor='contained-button-file'>
                <Input
                  accept='*'
                  id='contained-button-file'
                  type='file'
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                    if (
                      event.target.files[0].name.substr(
                        event.target.files[0].name.length - 4
                      ) !== '.csv'
                    ) {
                      setInvalidFormat(true);
                    } else {
                      setInvalidFormat(false);
                    }
                  }}
                  sx={{
                    display: 'none',
                  }}
                />
                <Button
                  disabled={isUploading}
                  component='span'
                  size='small'
                  sx={{
                    fontSize: '1rem',
                    borderRadius: '2rem',
                    textTransform: 'none',
                  }}
                >
                  <UploadFileRoundedIcon fontSize='small' />
                  Select File
                </Button>
              </label>
            </InputAdornment>
          ),
        }}
        placeholder={
          selectedFile
            ? invalidFormat
              ? 'Invalid file format'
              : selectedFile.name
            : 'Filename will appear here'
        }
        error={invalidFormat}
      />

      <Button
        component='span'
        variant='outlined'
        disabled={selectedFile === null || invalidFormat}
        onClick={callUploadFile}
        sx={{ fontSize: '1.3rem', textTransform: 'none' }}
        f
      >
        <UploadIcon fontSize='small' />
        {isUploading ? 'Uploading...' : 'Upload'}
      </Button>
    </Stack>
  );
}

export default StakingWalletFileUpload;
