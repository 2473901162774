import React from 'react';
import { Container } from '@mui/material';

export default function CustomCardContainer({ children }) {
  return (
    <Container
      maxWidth='xl'
      sx={{
        border: '1px solid rgba(46, 181, 224, 1)',
        borderRadius: '1rem',
        background: '#6AF2F099',
        mt: '1rem',
        mb: '1rem',
        pt: 2,
        pb: 2,
        textAlign: 'center',
      }}
    >
      {children}
    </Container>
  );
}
