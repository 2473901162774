import { walletServiceAPI } from './request';

export function loginAPI(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/login`,
    method: 'POST',
    data,
    headers: {},
  });
}

export function validateOtp(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/login/confirm_twofa`,
    method: 'POST',
    data,
    headers: {},
  });
}

export function getMissingFileUploadList() {
  return walletServiceAPI({
    endpoint: `/api/admin/upload/missing/${localStorage.getItem('authHash')}`,
    method: 'GET',
  });
}


export function getStakingConfirmedReq({ asId, startDate, endDate }) {
  return walletServiceAPI({
    endpoint: `/api/admin/s_uploads/${localStorage.getItem('authHash')}`,
    method: 'GET',
    params: {
      as_id: asId,
      start_date: startDate,
      end_date: endDate,
    },
  });
}



export function getBidRates(configType) {
  return walletServiceAPI({
    endpoint: `/api/fetch_rates`,
    method: 'GET',
    params: {
      config_type: configType,
    },
  });
}

export function setBidRates(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/set_rate`,
    method: 'POST',
    data,
  });
}

export function getPairs() {
  return walletServiceAPI({
    endpoint: `/api/fetch_pairs`,
    method: 'GET',
  });
}

export function addTokenSupply(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/add_token_supply`,
    method: 'POST',
    data,
  });
}

export function getSupplyTokens() {
  return walletServiceAPI({
    endpoint: `/api/fetch_tokens`,
    method: 'GET',
  });
}

export function resetWalletTwoFa(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/reset_wallet_twofa`,
    method: 'POST',
    data,
  });
}

export function getGenericConfirmedReq({ tag, startDate, endDate }) {
  return walletServiceAPI({
    endpoint: `/api/admin/g_uploads/${localStorage.getItem('authHash')}`,
    method: 'GET',
    params: {
      tag: tag,
      start_date: startDate,
      end_date: endDate,
    },
  });
}


//Generic
export function uploadGenericBalance(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/upload/generic`,
    method: 'POST',
    data,
  });
}

export function getGenericWalletReq() {
  return walletServiceAPI({
    endpoint: `/api/admin/upload/generic/${localStorage.getItem('authHash')}`,
    method: 'GET',
  });
}

export function approveRejectGenericWalletReq(data) {
  return walletServiceAPI({
    endpoint: `/api/admin/approve/upload/generic`,
    method: 'POST',
    data,
  });
}

// export function getStakingWalletReq() {
//   return walletServiceAPI({
//     endpoint: `/api/admin/upload/s_balance/${localStorage.getItem('authHash')}`,
//     method: 'GET',
//   });
// }

// export function getAsWalletReq() {
//   return walletServiceAPI({
//     endpoint: `/api/admin/upload/t_balance/${localStorage.getItem('authHash')}`,
//     method: 'GET',
//   });
// }

// export function uploadStakingBalance(data) {
//   return walletServiceAPI({
//     endpoint: `/api/admin/upload/s_balance`,
//     method: 'POST',
//     data,
//   });
// }

// export function uploadASBalance(data) {
//   return walletServiceAPI({
//     endpoint: `/api/admin/upload/t_balance`,
//     method: 'POST',
//     data,
//   });
// }

// export function approveRejectStakingWalletReq(data) {
//   return walletServiceAPI({
//     endpoint: `/api/admin/approve/upload/s_approve`,
//     method: 'POST',
//     data,
//   });
// }

// export function approveRejectAsWalletReq(data) {
//   return walletServiceAPI({
//     endpoint: `/api/admin/approve/upload/t_approve`,
//     method: 'POST',
//     data,
//   });
// }