import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Draggable from '../common/draggable';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { callApproveRejectGenericWalletReqAction } from '../../store/actions/wallet';

import CustomButton from '../common/CustomButtom';

function GenericWalletConfirmationModal({ payload = {}, closeModal }) {
  const { email_id, mode } = payload;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const button = {
    textTransform: 'none',
    fontSize: '1rem',
    boxShadow: 'none',
  };

  const callApproveRejectAsWalletReq = async (mode, email) => {
    const payload = {
      email_id: email,
      mode: mode,
      caller_email_id: localStorage.getItem('email'),
    };

    const { success, message } = await dispatch(
      callApproveRejectGenericWalletReqAction(payload)
    );

    if (success) {
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  return (
    <>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
              Confirm
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider sx={{ border: '3px solid', backgroundColor: '#000' }} />

      <CardContent>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
        >
          <Grid xs align='center'>
            <Typography>Are You Sure?</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container>
          <Grid xs={6} align='left'>
            <CustomButton
              btnText={mode === 'ACCEPT' ? 'Approve' : 'Reject'}
              handleSubmit={() => {
                callApproveRejectAsWalletReq(mode, email_id);
                closeModal();
              }}
            />
          </Grid>
          <Grid xs align='right'></Grid>
          <Button
            variant='outlined'
            onClick={closeModal}
            size='small'
            color='error'
            sx={button}
          >
            Cancel
          </Button>
        </Grid>
      </CardActions>
    </>
  );
}

export default function GenericWalletConfirmationModalWrapper(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return useMediaQuery('(min-width:600px)') ? (
    <Draggable>
      <Card variant='outlined'>
        <GenericWalletConfirmationModal {...props} />
      </Card>
    </Draggable>
  ) : (
    <Dialog fullScreen={fullScreen} fullWidth={true} open>
      <GenericWalletConfirmationModal {...props} />
    </Dialog>
  );
}
