// export const showStakingWalletConfirmationModal = (payload) => (dispatch) => {
//   dispatch({
//     type: 'SHOW_STAKING_WALLET_CONFIRM_MODAL',
//     payload: payload,
//   });
// };

// export const hideStakingWalletConfirmationModal = () => (dispatch) => {
//   dispatch({
//     type: 'HIDE_STAKING_WALLET_CONFIRM_MODAL',
//     payload: {},
//   });
// };

export const showGenericWalletConfirmationModal = (row) => (dispatch) => {
  dispatch({
    type: 'SHOW_GENERIC_WALLET_CONFIRM_MODAL',
    payload: row,
  });
};

export const hideGenericWalletConfirmationModal = () => (dispatch) => {
  dispatch({
    type: 'HIDE_GENERIC_WALLET_CONFIRM_MODAL',
    payload: {},
  });
};
