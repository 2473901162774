const initalstate = {
  genericWallet: [],
};

export default function reducer(state = initalstate, action) {
  switch (action.type) {
    case 'GET_GENERIC_WALLET':
      return {
        ...state,
        genericWallet: action.payload,
      };
    default:
      return state;
  }
}
