import React from 'react';
import { Button } from '@mui/material';

function CustomButton({
  btnText,
  handleSubmit,
  isDisabled = false,
  width = '40%',
}) {
  return (
    <Button
      disabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        handleSubmit();
      }}
      elevation={0}
      type='submit'
      variant='contained'
      sx={{
        width: width,
        bgcolor: '#2EB5E0',
        color: '#F0F0F0',
        textTransform: 'none',
        boxShadow: 'none',
        ':hover': {
          bgcolor: '#2EB5E0',
          color: '#F0F0F0',
          cursor: 'pointer',
          boxShadow: 'none',
        },
      }}
    >
      {btnText}
    </Button>
  );
}

export default CustomButton;
