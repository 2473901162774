import React, { useState } from 'react';
import AsConfirmed from '../confirmedWallet/AsConfirmed.jsx';
import StakingConfirmed from '../confirmedWallet/StakingConfirmed.jsx';
import MissingUploads from '../missingUpload.jsx';
import NonBlockingModal from '../modals/index.jsx';
import Rate from '../rate/index.jsx';
import GenericWallet from '../wallet/GenericWallet';
// import StakingWallet from '../wallet/StakingWallet';

export default function Main() {
  const [selectedTab, setSelectedTab] = useState('genericWallet');

  const handleToggleBtn = (type) => {
    if (type === 'genericWallet') {
      setSelectedTab(type);
    } 
    // else if (type === 'asWallet') {
    //   setSelectedTab(type);
    // } 
    else {
      setSelectedTab(type);
    }
  };

  return (
    <>
      {/* {selectedTab === 'stakingWallet' && (
        <StakingWallet
          selectedTab={selectedTab}
          handleToggleBtn={handleToggleBtn}
        />
      )} */}
      {selectedTab === 'genericWallet' && (
        <GenericWallet selectedTab={selectedTab} handleToggleBtn={handleToggleBtn} />
      )}

      {selectedTab === 'missingUploads' && (
        <MissingUploads
          selectedTab={selectedTab}
          handleToggleBtn={handleToggleBtn}
        />
      )}
      {selectedTab === 'stakingConfirmed' && (
        <StakingConfirmed
          selectedTab={selectedTab}
          handleToggleBtn={handleToggleBtn}
        />
      )}

      {selectedTab === 'asConfirmed' && (
        <AsConfirmed
          selectedTab={selectedTab}
          handleToggleBtn={handleToggleBtn}
        />
      )}

      {selectedTab === 'settings' && (
        <Rate selectedTab={selectedTab} handleToggleBtn={handleToggleBtn} />
      )}
      <div
        style={{
          position: 'fixed',
          zIndex: '1200',
          top: '100%',
          left: '15%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <NonBlockingModal />
      </div>
    </>
  );
}
