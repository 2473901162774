import {
  getBidRates,
  setBidRates,
  addTokenSupply,
} from '../../services/backendAPI';

export const getBidRatesAction = (configType) => (dispatch) => {
  getBidRates(configType)
    .then(({ data, success }) => {
      if (success) {
        dispatch({
          type: 'GET_BID_RATES',
          payload: data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const setBidRatesAction = (payload) => async (dispatch) => {
  try {
    const { success, message } = await setBidRates(payload);
    await dispatch(getBidRatesAction(payload.config_type));
    return { success, message };
  } catch (err) {
    return { success: false, message: err };
  }
};

export const addTokenSupplyAction = (payload) => async (dispatch) => {
  try {
    const { success, message } = await addTokenSupply(payload);
    return { success, message };
  } catch (err) {
    return { success: false, message: err };
  }
};
