import axios from 'axios';

export function walletServiceAPI({ method, endpoint, params, data, headers }) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: endpoint,
      headers: {
        Authorization: headers ?? localStorage.getItem('sessionToken'),
      },
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        // console.log('resp1', resp);
        // console.log('resp2', resp.data);
        // console.log('resp3', resp.data.success);

        if (resp && resp.data && resp.data.success) {
          resolve(data);
          // console.log('resp4', data);
        } else if (data.status === 'error') {
          reject(data.message || 'error');
        } else {
          reject(data.message || 'Invalid response');
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.clear();
          window.location.reload();
          return;
        }

        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}
