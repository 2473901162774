import {
  getStakingConfirmedReq,
  getGenericConfirmedReq,
} from '../../services/backendAPI';

const getDateMonthFormatted = (dateMonth) => {
  return dateMonth.toString().length === 2 ? dateMonth : `0${dateMonth}`;
};

export const getStakingConfirmedAction = (payload) => (dispatch) => {
  let startDate = new Date(payload.startDate).getDate();
  let startMonth = new Date(payload.startDate).getMonth() + 1;
  let startyear = new Date(payload.startDate).getFullYear();

  let endDate = new Date(payload.endDate).getDate();
  let endMonth = new Date(payload.endDate).getMonth() + 1;
  let endtyear = new Date(payload.endDate).getFullYear();

  let data = {
    ...payload,
    startDate: `${getDateMonthFormatted(startDate)}-${getDateMonthFormatted(
      startMonth
    )}-${startyear}`,
    endDate: `${getDateMonthFormatted(endDate)}-${getDateMonthFormatted(
      endMonth
    )}-${endtyear}`,
  };
  getStakingConfirmedReq(data)
    .then(({ data, success }) => {
      if (success) {
        dispatch({
          type: 'GET_STAKING_CONFIRMED',
          payload: data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getAsConfirmedAction = (payload) => (dispatch) => {
  let startDate = new Date(payload.startDate).getDate();
  let startMonth = new Date(payload.startDate).getMonth() + 1;
  let startyear = new Date(payload.startDate).getFullYear();

  let endDate = new Date(payload.endDate).getDate();
  let endMonth = new Date(payload.endDate).getMonth() + 1;
  let endtyear = new Date(payload.endDate).getFullYear();

  let data = {
    ...payload,
    startDate: `${getDateMonthFormatted(startDate)}-${getDateMonthFormatted(
      startMonth
    )}-${startyear}`,
    endDate: `${getDateMonthFormatted(endDate)}-${getDateMonthFormatted(
      endMonth
    )}-${endtyear}`,
  };
  getGenericConfirmedReq(data)
    .then(({ data, success }) => {
      if (success) {
        dispatch({
          type: 'GET_AS_CONFIRMED',
          payload: data,
        });
      }
    })
    .catch((err) => console.log(err));
};
