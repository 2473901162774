import {
  getGenericWalletReq,
  uploadGenericBalance,
  approveRejectGenericWalletReq,
} from '../../services/backendAPI';


export const getGenericWalletAction = () => (dispatch) => {
  getGenericWalletReq()
    .then(({ data, success }) => {
      if (success) {
        dispatch({
          type: 'GET_GENERIC_WALLET',
          payload: data,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const uploadGenericWalletAction = (payload) => async (dispatch) => {
  try {
    const { success, message } = await uploadGenericBalance(payload);
    await dispatch(getGenericWalletAction());
    return { success, message };
  } catch (err) {
    return { success: false, message: err };
  }
};

export const callApproveRejectGenericWalletReqAction =
  (payload) => async (dispatch) => {
    try {
      const { success, message } = await approveRejectGenericWalletReq(payload);
      await dispatch(getGenericWalletAction());
      return { success, message };
    } catch (err) {
      return { success: false, message: err };
    }
  };
