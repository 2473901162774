import React from 'react';
import { TextField } from '@mui/material';

function CustomTextfield({
  isFullWidth = false,
  id,
  label,
  name,
  variant,
  type,
  value,
  setValue,
  isDisabled = false,
}) {
  return (
    <TextField
      disabled={isDisabled}
      fullWidth={isFullWidth}
      id={id}
      label={label}
      name={name}
      variant={variant}
      type={type}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default CustomTextfield;
