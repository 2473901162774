import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { batchedSubscribe } from 'redux-batched-subscribe';
import debounce from 'lodash.debounce';
import modals from './reducers/modals';
import wallet from './reducers/wallet';
import confirmed from './reducers/confirmed';
import rates from './reducers/rates';

const debounceNotify = debounce((notify) => notify());
const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const rootReducer = combineReducers({
  modals,
  wallet,
  confirmed,
  rates,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk), batchedSubscribe(debounceNotify))
);

export default store;
