const initalstate = {
  genericWalletRejectConfirmation: {
    isOpen: false,
    payload: {},
  },
};

export default function reducer(state = initalstate, action) {
  switch (action.type) {
    case 'SHOW_GENERIC_WALLET_CONFIRM_MODAL':
      return {
        ...state,
        genericWalletRejectConfirmation: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_GENERIC_WALLET_CONFIRM_MODAL':
      return {
        ...state,
        genericWalletRejectConfirmation: {
          isOpen: false,
          payload: {},
        },
      };

    default:
      return state;
  }
}
