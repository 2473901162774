import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CustomButton from '../common/CustomButtom';
import { Grid, TextField } from '@mui/material';
import CustomTextfield from '../common/CustomTextfield';

function SearchBarWithDate({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  tag,
  setTag,
  onSearch,
}) {
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-around'
      alignItems='flex-start'
    >
      <Grid item xs={12} sm={12} md={3}>
        <CustomTextfield
          id='tag'
          label='TAG'
          name='tag'
          variant='outlined'
          type='text'
          value={tag}
          setValue={setTag}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label='Date desktop'
            inputFormat='dd-MM-yyyy'
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label='Date desktop'
            inputFormat='dd-MM-yyyy'
            value={endDate}
            onChange={(newDate) => setEndDate(newDate)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <CustomButton btnText={'Search'} handleSubmit={onSearch} />
      </Grid>
    </Grid>
  );
}

export default SearchBarWithDate;
