import React, { createRef } from 'react';
import './App.css';
import { SnackbarProvider } from 'notistack';
import Auth from './components/auth';
import Main from './components/main';
import { Button, Grid, ThemeProvider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

function App() {
  const theme = useTheme();
  const snackbarRef = createRef();
  const onClickDismiss = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position='relative'
        sx={{
          bgcolor: '#2EB5E0',
        }}
      >
        <Toolbar>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
          >
            <Grid item xs align='left'>
              <Typography
                variant='h6'
                color='#F0F0F0'
                noWrap
                sx={{
                  fontWeight: 600,
                }}
              >
                Admin Wallet Service
              </Typography>
            </Grid>
            {localStorage.getItem('sessionToken') && (
              <Grid item xs align='right'>
                <Typography
                  onClick={() => {
                    window.localStorage.clear();
                    window.location.reload();
                  }}
                  variant='h6'
                  color='#F0F0F0'
                  noWrap
                  sx={{
                    fontWeight: 600,
                    cursor: 'pointer',
                  }}
                >
                  Logout
                </Typography>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <main>
        <SnackbarProvider
          maxSnack={3}
          ref={snackbarRef}
          action={(key) => (
            <Button
              sx={{ color: '#fff', fontSize: '1em' }}
              size='small'
              onClick={onClickDismiss(key)}
            >
              Dismiss
            </Button>
          )}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {localStorage.getItem('sessionToken') ? <Main /> : <Auth />}
        </SnackbarProvider>
      </main>
    </ThemeProvider>
  );
}

export default App;
