export function stringifyNumberToIndianLocale(number, precision = 0) {
  return number.toLocaleString('en-IN', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

export function exchangeSpecificStringifiedNumber(precision = 2, number) {
  if (typeof number !== 'number') return '';
  return stringifyNumberToIndianLocale(number, precision);
}

export const copyGeneratedAddress = (publicAddress) => {
  navigator.clipboard.writeText(publicAddress);
  // enqueueSnackbar('address copied successfully !', { variant: 'success' });
};
