const initalstate = {
  stakingConfirmed: [],
  asConfirmed: [],
};

export default function reducer(state = initalstate, action) {
  switch (action.type) {
    case 'GET_STAKING_CONFIRMED':
      return {
        ...state,
        stakingConfirmed: action.payload,
      };
    case 'GET_AS_CONFIRMED':
      return {
        ...state,
        asConfirmed: action.payload,
      };

    default:
      return state;
  }
}
