import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomCardContainer from '../main/CustomCardContainer';
import { Typography, Stack } from '@mui/material';
import ToggleBtn from '../common/ToggleBtn';
import GenericTable from '../GenericTable';
import { getMissingFileUploadList } from '../../services/backendAPI';
import Link from '@mui/material/Link';

export default function MissingUploads({ selectedTab, handleToggleBtn }) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [missingFileUploads, setMissingFileUploads] = useState([]);

  const missingUploadFileHeaders = [
    {
      label: 'TYPE',
      keyName: 'upload_type',
      //   minWidth: 200,
      align: 'left',
    },
    {
      label: 'CREATED AT',
      keyName: 'created_at',
      // minWidth: 100,
      align: 'center',
    },

    {
      label: 'URL',
      keyName: 'upload_url',
      // minWidth: 100,
      align: 'right',
      formatter: ({ upload_url }) => (
        <Link target='_blank' href={upload_url}>
          {upload_url}
        </Link>
      ),
    },
  ];

  const getMissingFileUploadListReq = () => {
    getMissingFileUploadList()
      .then(({ data }) => setMissingFileUploads(data))
      .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
  };

  useEffect(() => {
    getMissingFileUploadListReq();
  }, []);

  return (
    <CustomCardContainer>
      <ToggleBtn activeTab={selectedTab} handleToggleBtn={handleToggleBtn} />
      <br />
      <GenericTable
        arrHeaders={missingUploadFileHeaders}
        arrRows={missingFileUploads}
        render={({ isHover, row, j }) => isHover && null}
        //   noResponseComponent={() => (
        //     <CommonMessages message={'No Channel Partners Found'} />
        //   )}
        //   onRowClick={(row) => dispatch(showAllChannelPartnerInfoModal(row))}
        mobileViewRowComponent={(row) => MissingUploadMobileComponent(row)}
      />
    </CustomCardContainer>
  );
}

const MissingUploadMobileComponent = (row) => {
  const { upload_type, created_at, upload_url } = row;

  return (
    <>
      <Typography display={'block'}>{`TYPE: ${upload_type}`}</Typography>
      <Typography display={'block'}>{`CREATED AT: ${created_at}`}</Typography>
      <Typography display={'block'}>{upload_url}</Typography>
      <br />
      <br />
    </>
  );
};
