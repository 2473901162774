import React, { useState } from 'react';
import { Grid, Container } from '@mui/material';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import authLogo from '../../assets/Group.png';
import appLogo from '../../assets/logo.png';
import { useSnackbar } from 'notistack';
import MD5 from 'md5-es';

import SignIn from './SignIn';
import ValidateOtp from './ValidateOtp';

import { loginAPI, validateOtp } from '../../services/backendAPI';

function Auth() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [authType, setAuthType] = useState('sign_in');
  const [loginPayload, setLoginPayload] = useState({});

  const handleToggleScreen = (type) => {
    if (type === authType) {
      return;
    } else {
      setAuthType(type);
    }
  };

  const handleSignIn = (email, password) => {
    if (email.length === 0) {
      enqueueSnackbar('please enter email', { variant: 'warning' });
    } else if (password.length === 0) {
      enqueueSnackbar('please enter password', { variant: 'warning' });
    } else {
      let payload = {
        email_id: email,
        password: MD5.hash(`${password}greekcollection@123`),
        device_id: 'web',
        salt_used: 'greekcollection@123',
      };
      loginAPI(payload)
        .then(({ data, message, success }) => {
          if (success) {
            setLoginPayload({ ...payload, password: password });
            const encryptedHash = MD5.hash(
              `${data.email_id}greekcollection@123`
            );
            localStorage.setItem('email', email);
            localStorage.setItem('username', data.username);
            localStorage.setItem('authHash', encryptedHash);
            localStorage.setItem('twoFaToken', data.SessionToken);
            handleToggleScreen('validate_otp');
            enqueueSnackbar(message, { variant: 'success' });
            // window.location.reload();
          } else {
            enqueueSnackbar(message, { variant: 'error' });
          }
        })
        .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
    }
  };

  const validateTwoCode = (otp) => {
    if (otp.length === 0) {
      enqueueSnackbar('please enter OTP', { variant: 'warning' });
    } else {
      let payload = {
        email_id: localStorage.getItem('email'),
        login_session_token: localStorage.getItem('twoFaToken'),
        two_fa_otp: otp,
      };

      validateOtp(payload)
        .then(({ data, message, success }) => {
          if (success) {
            localStorage.setItem('sessionToken', data.two_fa_session_token);
            enqueueSnackbar(message, { variant: 'success' });
            window.location.reload();
          } else {
            enqueueSnackbar(message, { variant: 'error' });
          }
        })
        .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
    }
  };

  return (
    <Container
      maxWidth='xl'
      style={{
        paddingTop: matches ? '10rem' : '2rem',
      }}
    >
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        {matches && (
          <Grid item xs={false} md={6}>
            <img src={authLogo} alt={authLogo} />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Card
            elevation={0}
            sx={{
              border: '1px solid rgba(46, 181, 224, 1)',
              borderRadius: '1rem',
              background: '#6AF2F099',
              p: 5,
            }}
          >
            <Grid
              spacing={2}
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
            >
              <Grid item xs align='center'>
                <img
                  src={appLogo}
                  alt={appLogo}
                  style={{
                    width: '20%',
                  }}
                />
              </Grid>
              {authType === 'sign_in' && <SignIn handleSignIn={handleSignIn} />}

              {authType === 'validate_otp' && (
                <ValidateOtp
                  validateTwoCode={validateTwoCode}
                  loginPayload={loginPayload}
                  handleSignIn={handleSignIn}
                />
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Auth;
