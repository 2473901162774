import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import GenericTable from '../GenericTable';
import { useSnackbar } from 'notistack';
import { exchangeSpecificStringifiedNumber } from '../../services/utilities';
import { Typography, Stack } from '@mui/material';
import CustomButton from '../common/CustomButtom';
import StakingWalletFileUpload from '../upload';
import CustomCardContainer from '../main/CustomCardContainer';
import ToggleBtn from '../common/ToggleBtn';
import CloseIcon from '@mui/icons-material/Close';
import { showGenericWalletConfirmationModal } from '../../store/actions/modals';
import {
  getGenericWalletAction,
  uploadGenericWalletAction,
} from '../../store/actions/wallet';

function GenericWallet({ selectedTab, handleToggleBtn }) {
  const dispatch = useDispatch();
  const { genericWallet } = useSelector((state) => state.wallet);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (file) => {
    if (file) {
      let bodyFormData = new FormData();
      bodyFormData.append('caller_email_id', localStorage.getItem('email'));
      bodyFormData.append('file', file);

      const { success, message } = await dispatch(
        uploadGenericWalletAction(bodyFormData)
      );

      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
      } else {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    dispatch(getGenericWalletAction());
  }, []);

  const genericWalletReqHeaders = [
    {
      label: 'INITIATOR EMAIL ID',
      keyName: 'initiator_email_id',
      //   minWidth: 200,
      align: 'left',
    },
    {
      label: 'PUBLIC ADDRESS',
      keyName: 'public_address',
      // minWidth: 100,
      align: 'center',
    },
    {
      label: 'BALANCE TO ADD',
      keyName: 'balance_to_add',
      //   minWidth: 200,
      align: 'center',
      formatter: ({ balance_to_add }) =>
        exchangeSpecificStringifiedNumber(2, balance_to_add),
    },
    {
      label: 'TAG',
      keyName: 'tag',
      // minWidth: 100,
      align: 'center',
    },
    {
      label: 'USER EMAIL ID',
      keyName: 'email_id',
      // minWidth: 100,
      align: 'right',
    },
    {
      label: '',
      keyName: '',
      // minWidth: 100,
      align: 'right',
      formatter: ({ email_id }) => (
        <CloseIcon
          onClick={() =>
            dispatch(
              showGenericWalletConfirmationModal({ email_id, mode: 'REJECT' })
            )
          }
          sx={{
            cursor: 'pointer',
            color: 'red',
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <CustomCardContainer>
        <ToggleBtn activeTab={selectedTab} handleToggleBtn={handleToggleBtn} />
        <br />
        <StakingWalletFileUpload
          fullScreen={fullScreen}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          invalidFormat={invalidFormat}
          setInvalidFormat={setInvalidFormat}
          isUploading={isUploading}
          callUploadFile={() => uploadFile(selectedFile)}
        />
        <br />
        <GenericTable
          arrHeaders={genericWalletReqHeaders}
          arrRows={genericWallet}
          render={({ isHover, row, j }) => isHover && null}
          //   noResponseComponent={() => (
          //     <CommonMessages message={'No Channel Partners Found'} />
          //   )}
          //   onRowClick={(row) => dispatch(showAllChannelPartnerInfoModal(row))}
          mobileViewRowComponent={(row) => StakingWalletReqMobileComponent(row)}
        />
      </CustomCardContainer>
      <Stack
        direction='row'
        justifyContent='right'
        alignItems='right'
        spacing={2}
        sx={{
          mr: 15,
        }}
      >
        <CustomButton
          isDisabled={genericWallet.length ? false : true}
          width={fullScreen ? '30%' : '10%'}
          btnText={'Approve All'}
          handleSubmit={() =>
            dispatch(
              showGenericWalletConfirmationModal({
                email_id: 'ALL',
                mode: 'ACCEPT',
              })
            )
          }
        />
        <CustomButton
          isDisabled={genericWallet.length ? false : true}
          width={fullScreen ? '30%' : '10%'}
          btnText={'Reject All'}
          handleSubmit={() =>
            dispatch(
              showGenericWalletConfirmationModal({ email_id: 'ALL', mode: 'REJECT' })
            )
          }
        />
      </Stack>
    </div>
  );
}

export default GenericWallet;

const StakingWalletReqMobileComponent = (row) => {
  const { initiator_email_id, public_address, balance_to_add, email_id } = row;

  return (
    <>
      <div>
        <Typography
          display={'block'}
        >{`INITIATOR EMAIL: ${initiator_email_id}`}</Typography>
        <Typography display={'block'}>{`USER EMAIL: ${email_id}`}</Typography>
        <Typography display={'block'}>{public_address}</Typography>
        <Typography display={'block'}>{`AMOUNT: ${balance_to_add}`}</Typography>
      </div>
      <br />
      <br />
    </>
  );
};
