import React, { useState } from 'react';
import { Grid } from '@mui/material';
import CustomTextfield from '../common/CustomTextfield';
import CustomButton from '../common/CustomButtom';
import Stack from '@mui/material/Stack';

function ValidateOtp({ validateTwoCode, handleSignIn, loginPayload }) {
  const [otp, setOtp] = useState('');

  return (
    <>
      <Grid
        item
        xs
        sx={{
          width: 'inherit',
        }}
      >
        <CustomTextfield
          isFullWidth={true}
          id='otp'
          label='OTP'
          name='otp'
          variant='outlined'
          type='number'
          value={otp}
          setValue={setOtp}
        />
      </Grid>
      <Grid
        item
        xs
        align='center'
        sx={{
          width: 'inherit',
        }}
      >
        <Stack
          sx={{ pt: 4 }}
          direction='row'
          spacing={2}
          justifyContent='center'
        >
          <CustomButton
            btnText={'Submit'}
            handleSubmit={() => validateTwoCode(otp)}
          />
          <CustomButton
            btnText={'Resend OTP'}
            handleSubmit={() =>
              handleSignIn(loginPayload.email_id, loginPayload.password)
            }
          />
        </Stack>
      </Grid>
    </>
  );
}

export default ValidateOtp;
