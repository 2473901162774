import React, { useState } from 'react';

import { Grid, Link } from '@mui/material';
import CustomTextfield from '../common/CustomTextfield';
import CustomButton from '../common/CustomButtom';
import Stack from '@mui/material/Stack';

function SignIn({ handleSignIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
      <Grid
        item
        xs
        sx={{
          width: 'inherit',
        }}
      >
        <CustomTextfield
          isFullWidth={true}
          id='email'
          label='Email'
          name='email'
          variant='outlined'
          type='text'
          value={email}
          setValue={setEmail}
        />
      </Grid>
      <Grid
        item
        xs
        sx={{
          width: 'inherit',
        }}
      >
        <CustomTextfield
          isFullWidth={true}
          id='password'
          label='Password'
          name='password'
          variant='outlined'
          type='password'
          value={password}
          setValue={setPassword}
        />
      </Grid>

      <Grid
        item
        xs
        align='center'
        sx={{
          width: 'inherit',
        }}
      >
        <Stack
          sx={{ pt: 4 }}
          direction='row'
          spacing={2}
          justifyContent='center'
        >
          <CustomButton
            btnText={'Submit'}
            handleSubmit={() => handleSignIn(email, password)}
          />
        </Stack>
      </Grid>
    </>
  );
}

export default SignIn;
