import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MD5 from 'md5-es';
import ToggleBtn from '../common/ToggleBtn';
import CustomCardContainer from '../main/CustomCardContainer';
import { Grid, Typography, TextField } from '@mui/material';
import {
  getBidRatesAction,
  setBidRatesAction,
  addTokenSupplyAction,
} from '../../store/actions/rates';
import CustomTextfield from '../common/CustomTextfield';
import CustomButton from '../common/CustomButtom';
import { useSnackbar } from 'notistack';
import {
  getPairs,
  getSupplyTokens,
  resetWalletTwoFa,
} from '../../services/backendAPI';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function Rate({ selectedTab, handleToggleBtn }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { rates } = useSelector((state) => state.rates);

  const [askRate, setAskRate] = useState('');
  const [bidRate, setBidRate] = useState('');
  const [tokenSupply, setTokenSupply] = useState(0);
  const [pairs, setPairs] = useState([]);
  const [selectedPair, setSelectedPair] = useState(0);
  const [supplyCoins, setSupplyCoins] = useState([]);
  const [selectedSupplyCoins, setSelectedSupplyCoins] = useState(1);
  const [resetTwoFaPassword, setResetTwoFaPassword] = useState({
    clientEmailId: '',
    password: '',
  });

  useEffect(() => {
    getPairs()
      .then(({ data }) => setPairs(data))
      .catch((err) => enqueueSnackbar(err, { variant: 'error' }));

    getSupplyTokens()
      .then(({ data }) => setSupplyCoins(data))
      .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
  }, []);

  useEffect(() => {
    if (pairs.length > 0) {
      dispatch(getBidRatesAction(pairs[selectedPair].pair));
    }
  }, [selectedPair, pairs]);

  useEffect(() => {
    if (rates.config_data !== undefined) {
      setAskRate(rates.config_data.ask_rate);
      setBidRate(rates.config_data.bid_rate);
    }
  }, [rates]);

  const setRatesForBidPrices = async (askRate, bidRate) => {
    let payload = {
      caller_email_id: localStorage.getItem('email'),
      config_type: pairs[selectedPair].pair,
      config_data: {
        bid_rate: Number(bidRate),
        ask_rate: Number(askRate),
      },
    };

    const { success, message } = await dispatch(setBidRatesAction(payload));

    if (success) {
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const addTokenSupply = async () => {
    let payload = {
      caller_email_id: localStorage.getItem('email'),
      token_symbol: supplyCoins[selectedSupplyCoins - 1].token_symbol,
      token_supply: tokenSupply,
    };

    const { success, message } = await dispatch(addTokenSupplyAction(payload));

    if (success) {
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
  };

  const handleChange = (event, updateState) => {
    updateState(event.target.value);
  };

  const resetWalletTwoFaPassword = () => {

    if (resetTwoFaPassword.clientEmailId.length === 0) {
      enqueueSnackbar('Please enter Client Email Id', { variant: 'warning' });
    } else if (resetTwoFaPassword.password.length === 0) {
      enqueueSnackbar('Please enter password', {
        variant: 'warning',
      });
    } else {
      const payload = {
        email_id: resetTwoFaPassword.clientEmailId,
        caller_email_id: localStorage.getItem('email'),
        caller_password: MD5.hash(
          `${resetTwoFaPassword.password}greekcollection@123`
        ),
      };

      resetWalletTwoFa(payload)
        .then(({ message, success }) => {
          if (success) {
            enqueueSnackbar(message, { variant: 'success' });
          }
        })
        .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
    }
  };

  return (
    <div>
      <CustomCardContainer>
        <ToggleBtn activeTab={selectedTab} handleToggleBtn={handleToggleBtn} />
        <br />
        <br />
        <Typography>Bid Rates</Typography>
        <br />
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='flex-start'
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={3}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedPair}
              label='select pair'
              onChange={(e) => handleChange(e, setSelectedPair)}
            >
              {pairs.map(({ id, pair }, index) => (
                <MenuItem key={index} value={index}>{pair}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CustomTextfield
              id='askRate'
              label='Ask Rate'
              name='askRate'
              variant='outlined'
              type='text'
              value={askRate}
              setValue={setAskRate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CustomTextfield
              id='bidRate'
              label='Bid Rate'
              name='bidRate'
              variant='outlined'
              type='text'
              value={bidRate}
              setValue={setBidRate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CustomButton
              btnText={'Update Rates'}
              handleSubmit={() => setRatesForBidPrices(askRate, bidRate)}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <Typography>Add Token Supply</Typography>
        <br />
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='flex-start'
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedSupplyCoins}
              label='select token supply'
              onChange={(e) => handleChange(e, setSelectedSupplyCoins)}
            >
              {supplyCoins.map(
                ({ id, token_name, token_symbol, token_type }) => (
                  <MenuItem
                    value={id}
                  >{`${token_name} [${token_symbol}]`}</MenuItem>
                )
              )}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomTextfield
              id='tokenSupply'
              label='Tokken Supply'
              name='tokenSupply'
              variant='outlined'
              type='text'
              value={tokenSupply}
              setValue={setTokenSupply}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomButton
              btnText={'Update Token Supply'}
              handleSubmit={() => addTokenSupply()}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <Typography>Reset TwoFa Password</Typography>
        <br />
        <br />
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='flex-start'
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              id={'clientEmailId'}
              label='Client Email'
              name='clientEmailId'
              variant='outlined'
              type='text'
              value={resetTwoFaPassword.clientEmailId}
              onChange={(e) =>
                setResetTwoFaPassword({
                  ...resetTwoFaPassword,
                  clientEmailId: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              id='adminPassword'
              label='Admin Password'
              name='adminPassword'
              variant='outlined'
              type='password'
              value={resetTwoFaPassword.password}
              onChange={(e) =>
                setResetTwoFaPassword({
                  ...resetTwoFaPassword,
                  password: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CustomButton
              btnText={'Update'}
              handleSubmit={() => resetWalletTwoFaPassword()}
            />
          </Grid>
        </Grid>
      </CustomCardContainer>
    </div>
  );
}

export default Rate;
