import React from 'react';
import Button from '@mui/material/Button';

function ToggleBtn({ activeTab, handleToggleBtn }) {

  return (
    <div>
      {/* <GetBtnTab
        handleToggleBtn={handleToggleBtn}
        btnText={'STAKING WALLET'}
        activeTab={activeTab}
        btnFor={'stakingWallet'}
      /> */}
      <GetBtnTab
        handleToggleBtn={handleToggleBtn}
        btnText={'WALLET UPLOAD'}
        activeTab={activeTab}
        btnFor={'genericWallet'}
      />

      <GetBtnTab
        handleToggleBtn={handleToggleBtn}
        btnText={'MISSING UPLOADS'}
        activeTab={activeTab}
        btnFor={'missingUploads'}
      />
{/* 
      <GetBtnTab
        handleToggleBtn={handleToggleBtn}
        btnText={'STAKING CONFIRMED'}
        activeTab={activeTab}
        btnFor={'stakingConfirmed'}
      /> */}
      <GetBtnTab
        handleToggleBtn={handleToggleBtn}
        btnText={'WALLET CONFIRMED'}
        activeTab={activeTab}
        btnFor={'asConfirmed'}
      />
      <GetBtnTab
        handleToggleBtn={handleToggleBtn}
        btnText={'SETTINGS'}
        activeTab={activeTab}
        btnFor={'settings'}
      />
    </div>
  );
}

export default ToggleBtn;

const GetBtnTab = ({ handleToggleBtn, btnText, activeTab, btnFor }) => (
  <Button
    onClick={() => handleToggleBtn(btnFor)}
    variant={activeTab === btnFor ? 'contained' : 'outlined'}
    sx={{
      elevation: 0,
      boxShadow: 'none',
      ml: 1,
      bgcolor: activeTab === btnFor ? '#2EB5E0' : 'transparent',
      color: activeTab === btnFor ? '#F0F0F0' : '#000',
      fontSize: '0.75rem',
      fontWeight: activeTab === btnFor ? 700 : 500,
      // p: '1rem 2.5rem',
      ':hover': {
        bgcolor: activeTab === btnFor ? '#2EB5E0' : 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    {btnText}
  </Button>
);
