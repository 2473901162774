import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ListItem, List } from '@mui/material';
import './style.css';
import { Hidden } from '@mui/material';

export default function GenericTable({
  arrHeaders,
  arrRows,
  noResponseComponent,
  onRowClick,
  render,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  pageSet,
  mobileViewRowComponent,
}) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('symbol');
  const [isHover, setIsHover] = useState(-1);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedColumns = (arrRow) => {
    const copyRow = [...arrRow];
    copyRow.sort((row1, row2) => {
      const a = row1[orderBy];
      const b = row2[orderBy];

      if (a === b) return 0;
      if (a < b) {
        return order === 'asc' ? -1 : 1;
      }
      return order === 'asc' ? 1 : -1;
    });
    return copyRow;
  };

  const onHoverRow = (row, key) => {
    setIsHover(key);
    return { row, key };
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Hidden only={['xs', 'sm']}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {arrHeaders.map(
                  ({ label, keyName, width, asset_class, formatter }) => (
                    <TableCell
                      key={keyName}
                      align={width}
                      style={{
                        minWidth: width,
                        backgroundColor: '#2EB5E0',
                        color: '#F0F0F0',
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === keyName}
                        direction={orderBy === keyName ? order : 'asc'}
                        onClick={() => handleRequestSort(keyName)}
                      >
                        {label}
                        {orderBy === keyName ? <span></span> : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {getSortedColumns(arrRows).map((row, i) => {
                return (
                  <TableRow
                    hover={true}
                    role='checkbox'
                    tabIndex={-1}
                    key={i}
                    onClick={(_event) =>
                      typeof onRowClick === 'function' && onRowClick(row, i)
                    }
                    onMouseOver={(_event) => onHoverRow(row, i)}
                    onMouseLeave={(_event) => setIsHover(-1)}
                  >
                    {arrHeaders.map(
                      (
                        { label, keyName, width, asset_class, formatter },
                        j
                      ) => {
                        return (
                          <TableCell key={j} align={width}>
                            {typeof formatter === 'function'
                              ? formatter(row, i)
                              : row[keyName]}
                            {row[asset_class] && (
                              <Typography
                                variant='caption'
                                gutterBottom
                                style={{
                                  fontSize: '11px',
                                  fontWeight: 300,
                                }}
                              >
                                {row[asset_class]}
                              </Typography>
                            )}
                          </TableCell>
                        );
                      }
                    )}
                    <div className='table-position'>
                      {render({ isHover: isHover === i, row, i })}
                    </div>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {arrRows.length === 0 &&
            typeof noResponseComponent === 'function' &&
            noResponseComponent()}
        </TableContainer>
      </Hidden>
      <Hidden mdUp>
        <List
          component='nav'
          style={{ scrollBehavior: 'smooth', overflow: 'auto' }}
        >
          {typeof mobileViewRowComponent === 'function' &&
            getSortedColumns(arrRows).map((row, i) => {
              return (
                <ListItem
                  onClick={(_event) =>
                    typeof onRowClick === 'function' && onRowClick(row, i)
                  }
                  button
                  key={i}
                >
                  {' '}
                  {mobileViewRowComponent(row)}{' '}
                </ListItem>
              );
            })}
        </List>
      </Hidden>
    </Paper>
  );
}
