import React from 'react';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
// import StakingWalletConfirmationModal from './StakingWalletConfirmationModal';
import GenericWalletConfirmationModalWrapper from './GenericWalletConfirmationModal';
import {
  hideGenericWalletConfirmationModal,
} from '../../store/actions/modals';

export default function NonBlockingModal(props) {
  const dispatch = useDispatch();
  const { genericWalletRejectConfirmation } =
    useSelector(({ modals }) => modals);

  const arrModals = [
    // {
    //   Component: StakingWalletConfirmationModal,
    //   modalState: stakingWalletRejectConfirmation,
    //   actionType: 'HIDE_STAKING_WALLET_CONFIRM_MODAL',
    // },
    {
      Component: GenericWalletConfirmationModalWrapper,
      modalState: genericWalletRejectConfirmation,
      actionType: 'HIDE_GENERIC_WALLET_CONFIRM_MODAL',
    },
  ];
  return (
    <>
      {arrModals.map(({ Component, modalState, actionType }, index) => {
        if (!modalState.isOpen) return null;
        return (
          <Slide
            direction='up'
            key={index}
            {...(modalState.isOpen ? { enter: 400 } : {})}
            in={modalState.isOpen}
            mountOnEnter
            unmountOnExit
          >
            <Paper elevation={0}>
              <Component
                key={index}
                payload={modalState !== undefined ? modalState.payload : {}}
                closeModal={() => {
                  if (modalState === genericWalletRejectConfirmation) 
                    dispatch(hideGenericWalletConfirmationModal());
                  // if (modalState === asWalletRejectConfirmation)
                  //   dispatch(hideAsWalletConfirmationModal());
                }}
                width={props.width}
              />
            </Paper>
          </Slide>
        );
      })}
    </>
  );
}
