import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ToggleBtn from '../common/ToggleBtn';
import CustomCardContainer from '../main/CustomCardContainer';
import { getAsConfirmedAction } from '../../store/actions/confirmed';
import SearchBarWithDate from './SearchBarWithDate';
import GenericTable from '../GenericTable';
import { exchangeSpecificStringifiedNumber } from '../../services/utilities';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import CustomButton from '../common/CustomButtom';
// import GenerateExcel from '../common/GenerateExcel.jsx';

function AsConfirmed({ selectedTab, handleToggleBtn }) {
  const dispatch = useDispatch();
  const _export = useRef(null);
  const { asConfirmed } = useSelector((state) => state.confirmed);
  const [tag, setTag] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const getAsConfirmedList = (tag, startDate, endDate) => {
    let Payload = {
      tag,
      startDate,
      endDate,
    };
    dispatch(getAsConfirmedAction(Payload));
  };

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const asConfirmedReqHeaders = [
    {
      label: 'INITIATOR EMAIL ID',
      keyName: 'initiator_email_id',
      //   minWidth: 200,
      align: 'left',
    },
    {
      label: 'PUBLIC ADDRESS',
      keyName: 'public_address',
      // minWidth: 100,
      align: 'center',
    },
    {
      label: 'BALANCE TO ADD',
      keyName: 'balance_to_add',
      //   minWidth: 200,
      align: 'center',
      formatter: ({ balance_to_add }) =>
        exchangeSpecificStringifiedNumber(2, balance_to_add),
    },
    {
      label: 'TAG',
      keyName: 'tag',
      // minWidth: 100,
      align: 'center',
    },
    {
      label: 'USER EMAIL ID',
      keyName: 'email_id',
      // minWidth: 100,
      align: 'right',
    },
  ];

  return (
    <div>
      <CustomCardContainer>
        <ToggleBtn activeTab={selectedTab} handleToggleBtn={handleToggleBtn} />
        <br />
        <br />

        <SearchBarWithDate
          tag={tag}
          setTag={setTag}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSearch={() => getAsConfirmedList(tag, startDate, endDate)}
        />
        <br />
        {asConfirmed.length > 0 && (
          <GenericTable
            arrHeaders={asConfirmedReqHeaders}
            arrRows={asConfirmed}
            render={({ isHover, row, j }) => isHover && null}
            //   noResponseComponent={() => (
            //     <CommonMessages message={'No Channel Partners Found'} />
            //   )}
            //   onRowClick={(row) => dispatch(showAllChannelPartnerInfoModal(row))}
            // mobileViewRowComponent={(row) => StakingWalletReqMobileComponent(row)}
          />
        )}
        <div style={{ display: 'none' }}>
          <ExcelExport data={asConfirmed} ref={_export}>
            <Grid
              data={asConfirmed}
              style={{
                height: '420px',
              }}
            >
              <GridColumn
                field='initiator_email_id'
                title='INITIATOR EMAIL ID'
                width='50px'
              />
              <GridColumn
                field='public_address'
                title='PUBLIC ADDRESS'
                width='350px'
              />
              <GridColumn field='balance_to_add' title='BALANCE TO ADD' />
              <GridColumn field='tag' title='TAG' />
              <GridColumn field='email_id' title='USER EMAIL ID' />
            </Grid>
          </ExcelExport>
        </div>

        {asConfirmed.length > 0 && (
          <>
            <br />
            <br />

            <CustomButton
              width={'20%'}
              btnText={'Download'}
              handleSubmit={excelExport}
            />
            {/* <GenerateExcel
              filename='Holdings'
              arrHeaders={asConfirmedReqHeaders}
              arrRows={asConfirmed}
              type='HOLDINGS'
            /> */}
          </>
        )}
      </CustomCardContainer>
    </div>
  );
}

export default AsConfirmed;
